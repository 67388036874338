import axios from 'axios';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/nextjs';
import _ from 'lodash';
import { LoginPageType } from 'constants/enum';
import pMemoize from 'p-memoize';
import ExpiryMap from 'expiry-map';
import {
  redirectToLoginPage,
  redirectToUrl,
} from '@asset/function/urlFunctions';

declare module 'axios' {
  export interface AxiosRequestConfig {
    handleError?: HandleError;
  }
}

type ErrorResponse = {
  message: string;
  code: string;
};

// When the errror is successfully handled, return true, else return false.
export type HandleError = (status: number, data: ErrorResponse) => boolean;

const tokenRefreshAPI = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_ENV_HOST}`,
  withCredentials: true,
});

const refreshTokenCache = new ExpiryMap(2000);
const refreshToken = pMemoize(
  async () => {
    return await tokenRefreshAPI.post('/api/users/token/refresh/');
  },
  { cache: refreshTokenCache },
);

const authAPI = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_ENV_HOST}`,
  withCredentials: true,
});

const handleResponseInternal = (response) => {
  return response;
};

const handleErrorInternal = async (error) => {
  const {
    method,
    url,
    params,
    data: requestData,
    headers,
    handleError,
  } = error.config; // axios의 error객체

  if (error.response && error.response?.status && error.response?.data) {
    const { data: responseData, status } = error.response;

    if (status === 401) {
      if (
        url !== '/api/users/token/refresh/' &&
        url !== '/api/users/token/blacklist/' &&
        (responseData.message === 'ACCESS_TOKEN_EXPIRED' ||
          responseData.message === 'ACCESS_TOKEN_NOT_FOUND')
      ) {
        const originalRequest = error.config;
        originalRequest._retry = true;
        try {
          const { data } = await refreshToken();
          const { access } = data;

          originalRequest.headers['Authorization'] = `Bearer ${access}`;
          authAPI.defaults.headers.common['Authorization'] = `Bearer ${access}`;

          return authAPI(originalRequest);
        } catch (error) {
          error.config = {
            ...error.config,
            handleError,
          };
          await handleErrorInternal(error);
          return Promise.reject(error);
        }
      } else if (responseData.code === 'LOGIN_FAILED') {
        toast.error('이메일 혹은 비밀번호를 확인해주세요.');
        return Promise.reject(error);
      } else {
        localStorage.removeItem('persistAtom');
      }
    }

    if (!_.isNil(handleError) && handleError(status, responseData)) {
      return Promise.reject(error);
    }
    if (status === 401) {
      redirectToLoginPage(LoginPageType.NORMAL, true);
      return Promise.reject(error);
    }
    if (status === 403) {
      redirectToUrl({
        pathname: '/_error',
      });
      if (error.response.data.code === 'TEST_IN_PROGRESS') {
        redirectToUrl({
          pathname: '/error/unsubmitted-test',
          query: {
            testAssignmentInProgress: JSON.stringify(
              error.response.data.data.testAssignmentInProgress,
            ),
          },
        });
      }
      return Promise.reject(error);
    }
    if (status === 409) {
      return Promise.reject(error);
    }
    if (Math.floor(status / 100) === 5) {
      toast.error('요청을 처리할 수 없습니다. 관리자에게 문의하세요.');
    }

    Sentry.setContext('API Request Detail', {
      method,
      url,
      params,
      requestData,
      headers,
    });
    Sentry.setContext('API Response Detail', {
      status,
      responseData,
    });
  }

  return Promise.reject(error);
};

authAPI.interceptors.response.use(handleResponseInternal, handleErrorInternal);

export { authAPI };
