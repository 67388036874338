import { atom, useRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import * as React from 'react';
import { UserInfo } from '@asset/type/userType';

const { persistAtom } = recoilPersist({
  key: `persistAtom`,
});

export const loginUserInfoState = atom<UserInfo | null>({
  key: `loginUserInfo`,
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const selectedStudentIdAtom = atom<number>({
  key: `selectedStudentId`,
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const studentListAtom = atom({
  key: `studentList`,
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export function useStudentListState() {
  const [isInitial, setIsInitial] = React.useState(true);
  const [studentList, setStudentList] = useRecoilState(studentListAtom);

  React.useEffect(() => {
    setIsInitial(false);
  }, []);

  return [isInitial === true ? null : studentList, setStudentList] as const;
}

export const studentInfoObjectAtom = atom({
  key: `studentInfoObject`,
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export function useStudentInfoObjectState() {
  const [isInitial, setIsInitial] = React.useState(true);
  const [studentInfoObject, setStudentInfoObject] = useRecoilState(
    studentInfoObjectAtom,
  );

  React.useEffect(() => {
    setIsInitial(false);
  }, []);

  return [
    isInitial === true ? null : studentInfoObject,
    setStudentInfoObject,
  ] as const;
}
