import Avatar, { AvatarProps } from 'boring-avatars';
import React from 'react';

const UserAvatarProfile = (props: AvatarProps) => {
  return (
    <Avatar
      {...props}
      variant="beam"
      colors={[
        '#FC9595',
        '#FFCFA8',
        '#FFEEA7',
        '#9AE3AF',
        '#7BD7EC',
        '#A387F7',
        '#FFB8BA',
      ]}
    />
  );
};

export default UserAvatarProfile;
