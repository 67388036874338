import styled from 'styled-components';
import tw from 'twin.macro';

export const Layout = styled.div`
  & {
    ${tw`flex justify-between px-6 py-4 pr-5 h-14`}
    background-color: ${(props) => props.backgroundColor};
    border-bottom: ${(props) =>
      props.isScrolled ? '1px solid var(--gray-80)' : ''};
    ${(props) => props.customStyle && props.customStyle};
  }
`;

export const StudentDropDownWrapper = styled.div`
  & {
    ${tw`relative flex items-center justify-end flex-1`}
    button {
      ${tw`text-body-lg-700 text-my-gray-10`}
    }
  }
`;

export const NavBarTitle = styled.div`
  & {
    ${tw`text-body-lg-700 text-my-gray-10`}
  }
`;
