import { css, createGlobalStyle } from 'styled-components';
import { deviceSize } from './breakpoints';

export const GlobalStyle = createGlobalStyle`
  & {
    @media ${deviceSize.md} {
      html {
        overscroll-behavior: none;
      }
    }
  }
`;

export const MobileViewStyle = createGlobalStyle`
  & {
    #__next,
        main {
          height: 100% !important;
        }
  }
`;

export const basicScrollBoxStyle = css`
  &::-webkit-scrollbar {
    display: block;
    width: 0.875rem;
    height: 100%;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--gray-70);
    width: 0.375rem;
    background-clip: padding-box;
    border: 0.25rem solid transparent;
    border-radius: 4rem;
  }
`;

export const getHoveredElementBackground = (originalBackgroundColor) => {
  return `linear-gradient(0deg, var(--Hover) 0%, var(--Hover) 100%), ${originalBackgroundColor}`;
};

export const getActiveElementBackground = (originalBackgroundColor) => {
  return `linear-gradient(0deg, var(--Active) 0%, var(--Active) 100%), ${originalBackgroundColor}`;
};
