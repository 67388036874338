import { breakPoints } from '@styles/breakpoints';
import { useEffect, useState } from 'react';

export const useWindowSize = () => {
  const isClient = typeof window === 'object';

  const getSize = () => {
    return { width: isClient ? window.innerWidth : undefined };
  };

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (isClient) {
      const handleResize = () => {
        setWindowSize(getSize());
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return { ...windowSize, isMobileView: windowSize.width < breakPoints.md };
};
