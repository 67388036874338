import styled from 'styled-components';

export const LnbStyle = styled.div`
  & {
    z-index: 5;
    width: 100%;
    display: flex;
    padding-top: 1rem;
    padding-left: 2.5rem;
    background-color: inherit;
    height: fit-content;
    border-bottom: 1px solid var(--gray-80);
  }
`;
