import { UrlObject } from 'url';
import { getUrlByQueryParameterObj } from './queryStringFunctions';
import { LoginPageType } from 'constants/enum';
import { getLoginPageUrlObject } from 'routes/account';

const getUrlPath = (url: UrlObject) => {
  const { pathname, query } = url;
  return pathname + getUrlByQueryParameterObj('', query);
};

export const redirectToUrl = (url: UrlObject) => {
  if (
    window.location.pathname.replace(/\/$/, '') ===
    url.pathname.replace(/\/$/, '')
  ) {
    return;
  }
  window.location.replace(getUrlPath(url));
};

export const redirectToLoginPage = (
  loginPageType: LoginPageType,
  includeRedirect: boolean,
) => {
  redirectToUrl(
    getLoginPageUrlObject({
      loginPageType: loginPageType,
      redirectUrl: includeRedirect
        ? window.location.pathname + window.location.search
        : null,
    }),
  );
};
