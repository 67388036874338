import styled from 'styled-components';
import tw from 'twin.macro';

export const BottomNavLayout = styled.ul`
  & {
    ${tw`sticky bottom-0 z-10 flex w-full px-4 bg-my-gray-100`}
    border-top: 1px solid var(--gray-80);
    height: var(--height-bottom-nav);
    box-sizing: border-box;
  }
`;

export const BottomNavItem = styled.li`
  & {
    ${tw`flex flex-col items-center justify-center flex-1 cursor-pointer text-label-xs-500`}
    gap: 0.12rem;
    padding: 0.81rem 0;
    p {
      color: ${(props) => props.color};
    }
    svg,
    path {
      fill: ${(props) => props.color};
    }
  }
`;
