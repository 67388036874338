import { StudentPage, TeacherPage } from '@asset/enum/page';
import { UserType } from '@asset/enum/UserEnum';
import { UrlObject } from 'url';

export const STUDENT_DASHBOARD_URL_PREFIX = '/dashboard/';
export const TEACHER_DASHBOARD_URL_PREFIX = '/manage/dashboard/';

export const getMyRoadmapPageUrlObject = (query: {
  studentId: number;
}): UrlObject => {
  const urlString = `${STUDENT_DASHBOARD_URL_PREFIX}plan/${StudentPage.MY_PLANS}`;
  return { pathname: urlString, query: query };
};

export const getMySchedulePageUrlObject = (query: {
  studentId: number;
}): UrlObject => {
  const urlString = `${STUDENT_DASHBOARD_URL_PREFIX}plan/${StudentPage.MY_SCHEDULE}`;
  return { pathname: urlString, query: query };
};

export const getMyLevelTestResultsPageUrlObject = (query: {
  studentId: number;
}): UrlObject => {
  const urlString = `${STUDENT_DASHBOARD_URL_PREFIX}analysis/${StudentPage.LEVEL_TEST}`;
  return { pathname: urlString, query: query };
};

export const getMyStudyRecordsPageUrlObject = (query?: {
  startDate?: string;
  studentId?: number;
  userType?: UserType;
}): UrlObject => {
  const urlString = `${STUDENT_DASHBOARD_URL_PREFIX}analysis/${StudentPage.MY_STUDY_RECORDS}`;
  return { pathname: urlString, query: query };
};

export const getMyCreditPageUrlObject = (): UrlObject => {
  const urlString = `${STUDENT_DASHBOARD_URL_PREFIX}my-page/${StudentPage.MY_CREDITS}`;
  return { pathname: urlString };
};

export const getManageStudyRecordsPageUrlObject = (
  studentId: number,
  query?: {
    startDateTime?: string;
    targetReportId?: number;
  },
): UrlObject => {
  const urlString = `${TEACHER_DASHBOARD_URL_PREFIX}${TeacherPage.STUDY_RECORDS}/${studentId}`;
  return { pathname: urlString, query: query };
};

export const getManageRoadmapPageUrlObject = (studentId: number): UrlObject => {
  const urlString = `${TEACHER_DASHBOARD_URL_PREFIX}${TeacherPage.ROADMAP}/${studentId}`;
  return { pathname: urlString };
};

export const getManageSchedulePageUrlObject = (
  studentId: number,
): UrlObject => {
  const urlString = `${TEACHER_DASHBOARD_URL_PREFIX}${TeacherPage.SCHEDULE}/${studentId}`;
  return { pathname: urlString };
};

export const getManageStudentInfoPageUrlObject = (
  studentId: number,
  query?: {
    edit?: boolean;
  },
): UrlObject => {
  const urlString = `${TEACHER_DASHBOARD_URL_PREFIX}${TeacherPage.STUDENT_INFORMATION}/${studentId}`;
  return { pathname: urlString, query };
};
