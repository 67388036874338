import { basicScrollBoxStyle } from '@styles/globalsStyles';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

const FilterArrow = styled.div`
  & {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
`;

export const OpenFilterArrow = styled(FilterArrow)`
  border-bottom: 6px solid ${(props) => props.color};
  border-top: 0px solid transparent;
`;

export const CloseFilterArrow = styled(FilterArrow)`
  border-top: 6px solid ${(props) => props.color};
  border-bottom: 0px solid transparent;
`;

export const BasicDropDownMenuStyle = css`
  ${tw`absolute overflow-y-auto`}
  ${basicScrollBoxStyle}

  > li {
    ${tw`cursor-pointer`}
  }
  > li[aria-selected='true'] {
    ${tw`bg-my-gray-90`}
  }
  > li:hover {
    ${tw`bg-my-gray-80`}
  }
`;

export const BasicDropDownMenuUlStyle = styled.ul`
  & {
    ${BasicDropDownMenuStyle}
    ${tw`w-[7.5rem] rounded-lg border border-solid border-my-gray-80 absolute right-0 top-[calc(100% + 0.25rem)] py-1`}
    box-shadow: 0px 4px 16px 0px rgba(25, 32, 41, 0.16);

    > li {
      ${tw`p-4 cursor-pointer bg-my-gray-100 text-body-md-500 text-my-gray-10`}
    }
    > li[aria-selected='true'] {
      ${tw`bg-my-gray-80`}
    }
  }
`;

export const BasicDropDownStyle = styled.div`
  & {
    ${tw`relative`}
    min-width:${(props) => props.width};
    width: fit-content;

    > div {
      > button {
        ${tw`flex items-center justify-between w-full cursor-pointer`}
      }
    }

    > ul {
      ${BasicDropDownMenuStyle}
    }
  }
`;

export const BasicDropDownUlStyle = css`
  & {
    ${tw`py-1 mt-1 border border-solid rounded-lg border-my-gray-70 bg-my-gray-100`}
    box-shadow: 0px 8px 24px 0px rgba(25, 32, 41, 0.16);

    > li {
      ${tw`p-3 text-label-lg-500 text-my-gray-10 hover:bg-my-gray-80`}
    }
  }
`;

export const ProblemSolvingHamburgerFilterStyle = styled(BasicDropDownStyle)`
  & {
    > div {
      button {
        > span {
          ${tw`text-old-my-grey-500`}
        }
      }
    }

    > ul {
      ${tw`p-2 bg-white border border-solid rounded-md border-old-my-grey-400`}
      width: 120%;
      left: 50%;
      transform: translateX(-50%);

      > li {
        ${tw`p-1 font-medium rounded-sm`}
      }
    }
  }
`;

export const ListTableFilterStyle = styled(BasicDropDownStyle)`
  & {
    > div {
      ${tw`flex items-center p-3 border border-solid rounded-lg border-my-gray-70 text-label-lg-500`}
      width: 100%;
      height: 2.5rem;
      background-color: white;

      > button {
        gap: 8px;
      }
    }

    > ul {
      ${BasicDropDownUlStyle}
      max-height: 20rem;
      min-width: 6.8125rem;
      width: 100%;
      z-index: 3333;
    }
  }
`;

export const ListDropdownFilterStyle = styled(BasicDropDownStyle)`
  & {
    > div {
      ${tw`rounded-lg w-fit min-w-fit`}
      padding:${(props) => (props.padding ? props.padding : '')};
      &:hover {
        background-color: ${(props) =>
          props.hoverColor ? props.hoverColor : 'var(--gray-90)'};
      }

      button {
        width: fit-content;
        background-color: transparent;
        justify-content: flex-start;
        gap: 0.25rem;

        span {
          ${tw`text-body-md-500 text-my-gray-10`}
        }
      }
    }

    > ul {
      top: ${(props) =>
        props?.listPosition?.top ? props.listPosition.top : '100%'};
      right: ${(props) =>
        props?.listPosition?.right ? props.listPosition.right : ''};

      min-width: 6rem;
      height: fit-content;
      max-height: 30vh;
      padding: 0.5rem 0;

      background-color: white;
      border: 1px solid var(--gray-70);
      border-radius: 0.5rem;
      z-index: 1;

      box-shadow: 0px 8px 24px 0px rgba(23, 32, 42, 0.16);
      box-shadow: 0px 8px 24px 0px color(display-p3 0.098 0.1255 0.1608 / 0.16);

      > li {
        padding: 0.62rem 1rem;
        width: 100%;
        cursor: pointer;
        &:hover {
          background-color: var(--gray-90);
        }

        span {
          width: 100%;
        }
      }
    }
  }
`;

export const SelectStudentDropdownStyle = styled.div`
  & {
    position: absolute;
    top: 2.3rem;
    right: 3rem;
  }
`;

export const ScheduleItemDropdownFilterStyle = styled.div`
  & {
    border-radius: var(--radius-small, 0.5rem);
    border: 1px solid var(--gray-70, #cad5de);
    background: var(---gray-100, #fff);
    box-shadow: 0px 8px 24px 0px rgba(23, 32, 42, 0.16);

    ul {
      ${tw`py-2`}

      li {
        ${tw`py-[0.62rem] px-4 flex items-center gap-2 text-body-md-500`}
      }
      li:hover {
        ${tw`bg-Hover`}
      }
      li:active {
        ${tw`bg-Active`}
      }
    }
  }
`;
