import { getStudentInfo } from '@apis/api/dashboard';
import { UserType } from '@asset/enum/UserEnum';
import { UserInfo } from '@asset/type/userType';
import { loginUserInfoState, selectedStudentIdAtom } from '@atoms/states';
import _ from 'lodash';
import { Settings } from 'luxon';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';

const getStudentData = async (studentId) => {
  const studentInfoData = await getStudentInfo(studentId);
  if (!_.isNil(studentInfoData)) {
    return {
      id: studentId,
      type: UserType.STUDENT,
      name: studentInfoData.name,
      timezone: studentInfoData.timezone,
      language: studentInfoData.language,
      grade: studentInfoData.grade,
    };
  } else {
    return null;
  }
};

export function useSelectedStudentInfoQuery() {
  const [selectedStudentId] = useRecoilState(selectedStudentIdAtom);

  return useQuery(
    ['GET_STUDENT_INFO_QUERY', selectedStudentId],
    async () => {
      const studentData = await getStudentData(selectedStudentId);
      Settings.defaultZone = studentData.timezone;
      return studentData;
    },
    {
      enabled: Boolean(selectedStudentId),
      cacheTime: 0,
      staleTime: 1 * 60 * 1000,
    },
  );
}

export function useUserInfoQuery() {
  const [user, setUser] = useRecoilState<UserInfo>(loginUserInfoState);
  return useQuery(
    ['GET_USER_INFO_QUERY', user],
    async () => {
      // TODO: user없을때 로그인페이지로 리다이렉트 처리
      if (!user || !user.id) {
        return null;
      }
      if (user.type === UserType.STUDENT) {
        const studentData = await getStudentData(user.id);
        setUser({ userId: user.userId, ...studentData }); //TODO 이 부분 없애는게 좋을 것 같음 -> 그러기 위해서는 userId 전역변수 하나 만들고, 선생님 user 정보도 api로 불러오는 것이 좋을 것 같음
        return { userId: user.userId, ...studentData };
      } else return user;
    },
    {
      staleTime: 5 * 60 * 1000,
    },
  );
}
