import React from 'react';

const OpenedBookIcon = ({ width = '16', height = '16', color = 'white' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={'0 0 16 16'}
      fill="none"
    >
      <path
        d="M8.00008 13.0333C7.87786 13.0333 7.75841 13.0167 7.64175 12.9833C7.52508 12.95 7.41675 12.9055 7.31675 12.85C6.86119 12.5722 6.38341 12.3611 5.88341 12.2167C5.38341 12.0722 4.86675 12 4.33341 12C3.86675 12 3.40841 12.0611 2.95841 12.1833C2.50841 12.3055 2.07786 12.4778 1.66675 12.7C1.43341 12.8222 1.20841 12.8167 0.991748 12.6833C0.775081 12.55 0.666748 12.3555 0.666748 12.1V4.06666C0.666748 3.94444 0.697304 3.82777 0.758415 3.71666C0.819526 3.60555 0.911192 3.52221 1.03341 3.46666C1.54453 3.19999 2.07786 2.99999 2.63341 2.86666C3.18897 2.73332 3.75564 2.66666 4.33341 2.66666C4.97786 2.66666 5.60841 2.74999 6.22508 2.91666C6.84175 3.08332 7.43341 3.33332 8.00008 3.66666V11.7333C8.56675 11.3778 9.16119 11.1111 9.78341 10.9333C10.4056 10.7555 11.0334 10.6667 11.6667 10.6667C12.0667 10.6667 12.4584 10.7 12.8417 10.7667C13.2251 10.8333 13.6112 10.9333 14.0001 11.0667V3.06666C14.1667 3.12221 14.3306 3.18055 14.4917 3.24166C14.6529 3.30277 14.8112 3.37777 14.9667 3.46666C15.089 3.52221 15.1806 3.60555 15.2417 3.71666C15.3029 3.82777 15.3334 3.94444 15.3334 4.06666V12.1C15.3334 12.3555 15.2251 12.55 15.0084 12.6833C14.7917 12.8167 14.5667 12.8222 14.3334 12.7C13.9223 12.4778 13.4917 12.3055 13.0417 12.1833C12.5917 12.0611 12.1334 12 11.6667 12C11.1334 12 10.6167 12.0722 10.1167 12.2167C9.61675 12.3611 9.13897 12.5722 8.68341 12.85C8.58341 12.9055 8.47508 12.95 8.35841 12.9833C8.24175 13.0167 8.1223 13.0333 8.00008 13.0333ZM9.88341 9.49999C9.7723 9.59999 9.65286 9.61944 9.52508 9.55832C9.3973 9.49721 9.33341 9.39443 9.33341 9.24999V3.79999C9.33341 3.75555 9.34175 3.71388 9.35841 3.67499C9.37508 3.6361 9.40008 3.59999 9.43341 3.56666L12.1001 0.899991C12.2112 0.788879 12.3334 0.761102 12.4667 0.816657C12.6001 0.872213 12.6667 0.977768 12.6667 1.13332V6.84999C12.6667 6.90555 12.6556 6.95277 12.6334 6.99166C12.6112 7.03055 12.5834 7.06666 12.5501 7.09999L9.88341 9.49999Z"
        fill={color}
      />
    </svg>
  );
};

export default OpenedBookIcon;
