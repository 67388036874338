import { getStudentListApi, getTeacherPageList } from '@apis/api/dashboard';
import { TeacherStatus, TeacherPosition } from '@asset/enum/UserEnum';
import { useQuery } from 'react-query';

export const useFilteringTeachersQuery = () =>
  useQuery(
    ['GET_FILTERING_TEACHERS'],
    async () =>
      await getTeacherPageList({
        pageType: 'side-bar',
        statusList: [TeacherStatus.ACTIVE],
        positionList: [TeacherPosition.MAIN],
      }),
  );

export const useFilteringStudentsQuery = () =>
  useQuery(['GET_FILTERING_STUDENTS'], async () => await getStudentListApi());
