import { DialogModalStyle } from '@styles/common/modal/modalStyles';
import React, { useState } from 'react';
import BasicButton from '../button/BasicButton';
import {
  BasicButtonAssetType,
  BasicButtonColor,
} from '@asset/type/common/buttonType';

interface Props {
  title: string;
  content: (string | JSX.Element)[];
  buttons: {
    color: BasicButtonColor;
    assetType: BasicButtonAssetType;
    content;
    buttonType?: 'submit' | 'reset' | 'button' | null;
    onClickEvent: ((e: React.MouseEvent<HTMLButtonElement>) => void) | null;
  }[];
}

const Dialog = ({ title, content, buttons }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <DialogModalStyle className="modal">
      <div className={`modal-content p-6`}>
        <div className={`body`}>
          <div>{title}</div>
          <div>
            {content.map((content, index) => (
              <div key={index}>{content === '' ? <br /> : content}</div>
            ))}
          </div>
        </div>

        <div className={`modal-footer`}>
          {buttons.map((button, index) => (
            <div key={index}>
              <BasicButton
                color={button.color}
                assetType={button.assetType}
                size={'M'}
                content={button.content}
                onClickEvent={(e) => {
                  setIsSubmitting(true);
                  button.onClickEvent(e);
                }}
                isActive={!isSubmitting}
                buttonType={button.buttonType}
              />
            </div>
          ))}
        </div>
      </div>
    </DialogModalStyle>
  );
};

export default Dialog;
