import { usersLogOut } from '@apis/api/auth';
import router from 'next/router';
import { getLoginPageUrlObject } from 'routes/account';
import * as amplitude from '@amplitude/analytics-browser';
import cookieCutter from 'cookie-cutter';

export const logoutAndResetUserInfo = async () => {
  await usersLogOut();
  await router.replace(getLoginPageUrlObject({}));
  amplitude.reset();
  cookieCutter.set('NEXT_LOCALE', '', {
    path: '/',
  });
};
