import { ArrowDropUpIcon, ArrowDropDownIcon } from '@asset/svg/ArrowDropIcon';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import * as S from '@styles/common/nav/MobileTopNavBar.style';
import {
  selectedStudentIdAtom,
  useStudentInfoObjectState,
  useStudentListState,
} from '@atoms/states';
import { useRecoilState } from 'recoil';
import { usersLogOut } from '@apis/api/auth';
import { UserType } from '@asset/enum/UserEnum';
import { setUser } from '@sentry/nextjs';
import { LoginPageType } from 'constants/enum';
import { getLoginPageUrlObject } from 'routes/account';
import * as amplitude from '@amplitude/analytics-browser';
import cookieCutter from 'cookie-cutter';
import Dialog from '../modal/Dialog';
import {
  useSelectedStudentInfoQuery,
  useUserInfoQuery,
} from '@apis/query/user';
import BasicMenu from '../list/BasicMenu';
import { useTranslation } from 'next-i18next';

const MobileTopNavBar = ({
  customStyle = null,
}: {
  customStyle?: {
    backgroundColor?: string;
    borderBottom?: string;
  };
}) => {
  const { t } = useTranslation('mobileTopNavBar');
  const router = useRouter();
  const [openStudentNameDropdown, setOpenStudentNameDropdown] =
    useState<boolean>(false);
  const [studentList, setStudentList] = useStudentListState();
  const [openLogoutDialog, setOpenLogoutDialog] = useState<boolean>(false);
  const [, setSelectedStudentId] = useRecoilState(selectedStudentIdAtom);
  const [, setStudentInfoObject] = useStudentInfoObjectState();
  const { data: selectedStudent } = useSelectedStudentInfoQuery();
  const { data: user } = useUserInfoQuery();
  const [studentName, setStudentName] = useState(
    user?.type === UserType.STUDENT ? user.name : selectedStudent?.name,
  );

  const studentId =
    user?.type === UserType.STUDENT ? user.id : selectedStudent?.id;

  useEffect(() => {
    if (user?.type === UserType.STUDENT) setStudentName(user.name);
    else {
      if (selectedStudent) setStudentName(selectedStudent.name);
    }
  }, [selectedStudent, user]);

  return (
    <S.Layout customStyle={customStyle}>
      <S.NavBarTitle>
        {router.asPath.includes('my-study-records')
          ? t('mobileTopNavBar:title.weeklyStudyReport')
          : router.asPath.includes('my-schedule')
          ? '학습 일정표'
          : router.asPath.includes('my-plans')
          ? '학습 로드맵'
          : router.asPath.includes('level-test')
          ? '진단 리포트'
          : ''}
      </S.NavBarTitle>
      <S.StudentDropDownWrapper>
        <button
          onClick={() => {
            setOpenStudentNameDropdown((prev) => !prev);
          }}
        >
          {studentName}
        </button>
        {openStudentNameDropdown ? (
          <ArrowDropUpIcon width="1rem" height="1rem" />
        ) : (
          <ArrowDropDownIcon width="1rem" height="1rem" />
        )}
        {openStudentNameDropdown ? (
          <BasicMenu
            items={
              studentList?.length > 0 && user.type === UserType.PARENT
                ? [
                    ...studentList?.map((obj) => {
                      return {
                        id: obj.id,
                        content: obj.name,
                        selected: obj.id === studentId,
                      };
                    }),
                    {
                      id: 'logout',
                      content: '로그아웃',
                      selected: false,
                    },
                  ]
                : [
                    {
                      id: 'logout',
                      content: '로그아웃',
                      selected: false,
                    },
                  ]
            }
            customStyles={{
              top: '1.5rem',
              right: '0',
            }}
            onClickAway={() => {
              setOpenStudentNameDropdown(false);
            }}
            onClickItem={async (selectedItem) => {
              if (selectedItem.id === 'logout') {
                setOpenLogoutDialog(true);
              } else {
                setOpenStudentNameDropdown(false);
                setSelectedStudentId(Number(selectedItem.id));
                router.query.studentId = selectedItem.id as string;
                router.replace(router);
              }
            }}
          />
        ) : (
          <></>
        )}
      </S.StudentDropDownWrapper>
      {openLogoutDialog && (
        <Dialog
          title={'로그아웃'}
          content={['로그아웃 하시겠습니까?']}
          buttons={[
            {
              color: 'gray',
              assetType: 'Secondary',
              content: '취소',
              buttonType: 'button',
              onClickEvent: () => setOpenLogoutDialog(false),
            },
            {
              color: 'red',
              assetType: 'Primary',
              content: '로그아웃',
              buttonType: 'button',
              onClickEvent: async () => {
                await usersLogOut();
                router.replace(
                  getLoginPageUrlObject({
                    loginPageType:
                      user?.type === UserType.PARENT
                        ? LoginPageType.PARENTS
                        : LoginPageType.NORMAL,
                  }),
                );
                setUser(null);
                setSelectedStudentId(null);
                setStudentList(null);
                setStudentInfoObject(null);
                amplitude.reset();
                cookieCutter.set('NEXT_LOCALE', '', {
                  path: '/',
                });
              },
            },
          ]}
        />
      )}
    </S.Layout>
  );
};

export default MobileTopNavBar;
