import React, { useEffect, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { useEventPropertiesStore } from '@store/common';
import { useRouter } from 'next/router';
import { LnbStyle } from '@styles/common/lnbStyles';
import { useSelectedStudentInfoQuery } from '@apis/query/user';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

interface Props {
  list: {
    href: string;
    text: string;
    pageType: string;
  }[];
}

const Lnb = ({ list }: Props) => {
  const ACTIVE_COLOR = 'var(--gray-10)';
  const HOVER_COLOR = 'var(--gray-50)';
  const router = useRouter();
  const { properties } = useEventPropertiesStore();
  const { data: selectedStudent } = useSelectedStudentInfoQuery();
  const [value, setValue] = useState(0);

  const handleLnbMenuClick = (value) => {
    if (value?.pageType) {
      amplitude.track(`click_dashboard_sub_nav_${value?.pageType}`, {
        ...properties,
        pageType: value?.pageType,
        studentId: selectedStudent?.id,
      });
    } else if (value.href.includes('study-status-list')) {
      amplitude.track(
        `click_teacher_${
          value.text === '전체 활동' ? 'total' : 'last'
        }_study_status_list`,
      );
    }
  };

  useEffect(() => {
    list?.map((value, index) => {
      if (value.href.split('?')[0].includes(router.asPath.split('?')[0]))
        setValue(index);
    });
  }, [list]);

  return (
    <LnbStyle>
      <div className="relative">
        <Tabs
          value={value}
          TabIndicatorProps={{
            style: { height: '3px', background: ACTIVE_COLOR },
          }}
          sx={{
            '.MuiButtonBase-root': {
              padding: '0 0 0.3125rem 0',
              minHeight: 0,
              minWidth: 0,
              textTransform: 'none',
              fontFamily: 'Pretendard',
            },
            '.MuiTabs-scroller': {
              height: 'fit-content',
            },
            '.MuiTabs-flexContainer': {
              gap: '1.5rem',
            },
            minHeight: 0,
          }}
        >
          {list?.map((value, index) => {
            return ['Comment', 'Question', 'Test Result'].includes(
              value.text,
            ) ? null : (
              <Tab
                key={index}
                label={value.text}
                disableRipple
                sx={{
                  fontSize: '1rem',
                  fontWeight: '700',
                  lineHeight: '1.5rem',
                  color: 'var(--gray-60)',
                  '&.Mui-selected': {
                    color: ACTIVE_COLOR,
                  },
                  ':hover:not(.Mui-selected)': {
                    color: HOVER_COLOR,
                  },
                }}
                onClick={() => {
                  router.push(value.href);
                  handleLnbMenuClick(value);
                }}
              />
            );
          })}
        </Tabs>
      </div>
    </LnbStyle>
  );
};

export default Lnb;
