import React from 'react';

const RoadMapIcon = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M6.33325 7C6.33325 5.34315 7.6764 4 9.33325 4H18.3333C19.9901 4 21.3333 5.34315 21.3333 7V8C21.3333 9.65685 19.9901 11 18.3333 11H9.33325C7.6764 11 6.33325 9.65685 6.33325 8V7Z"
        fill={color}
      />
      <path
        d="M3.33325 16C3.33325 14.3431 4.6764 13 6.33325 13H15.3333C16.9901 13 18.3333 14.3431 18.3333 16V17C18.3333 18.6569 16.9901 20 15.3333 20H6.33325C4.6764 20 3.33325 18.6569 3.33325 17V16Z"
        fill={color}
      />
    </svg>
  );
};

export default RoadMapIcon;
