import qs from 'qs';
import { authAPI } from '../../core/instance';
import { DateTime } from 'luxon';

//TODO: DELETE
export const getContinueStudyContentInfo = async (pageType, studyContentId) => {
  const apiUrl = `/api/study-data/last-problem/${pageType}/${studyContentId}/`;

  const data = await authAPI
    .get(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
  return data;
};

export const linkTeacherApi = async (reqBody) => {
  const data = await authAPI
    .patch(`/api/teachers/linking/student/`, reqBody)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getStudentActivitySummaryApi = async (
  startDateTime: DateTime,
  endDateTime: DateTime,
) => {
  let url = '/api/study-data/activity-summary/';
  const queryString = qs.stringify({
    startDateTime: startDateTime.toISO(),
    endDateTime: endDateTime.toISO(),
  });
  url += `?${queryString}`;
  const data = await authAPI
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getStudentsExtrasApi = async (queryParameters: {
  studentIds: number[];
}): Promise<{
  success: boolean;
  data: {
    studentId: number;
    avatarName: string;
  }[];
}> => {
  const queryString = qs.stringify(queryParameters, {
    encodeValuesOnly: true,
    arrayFormat: 'comma',
  });
  const url = `/api/students/status/?${queryString}`;
  const data = await authAPI
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
