import React from 'react';

const BarChartIcon = ({
  width = '16',
  height = '16',
  color = 'var(--blue-50)',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={'0 0 16 16'}
      fill="none"
    >
      <path
        d="M2.66683 12.6667H5.3335V7.33333H2.66683V12.6667ZM6.66683 12.6667H9.3335V3.33333H6.66683V12.6667ZM10.6668 12.6667H13.3335V8.66667H10.6668V12.6667ZM1.3335 12.6667V7.33333C1.3335 6.96667 1.46405 6.65278 1.72516 6.39167C1.98627 6.13056 2.30016 6 2.66683 6H5.3335V3.33333C5.3335 2.96667 5.46405 2.65278 5.72516 2.39167C5.98627 2.13056 6.30016 2 6.66683 2H9.3335C9.70016 2 10.0141 2.13056 10.2752 2.39167C10.5363 2.65278 10.6668 2.96667 10.6668 3.33333V7.33333H13.3335C13.7002 7.33333 14.0141 7.46389 14.2752 7.725C14.5363 7.98611 14.6668 8.3 14.6668 8.66667V12.6667C14.6668 13.0333 14.5363 13.3472 14.2752 13.6083C14.0141 13.8694 13.7002 14 13.3335 14H2.66683C2.30016 14 1.98627 13.8694 1.72516 13.6083C1.46405 13.3472 1.3335 13.0333 1.3335 12.6667Z"
        fill={color}
      />
    </svg>
  );
};
export const FilledBarChartIcon = ({
  width = '16',
  height = '16',
  color = 'var(--blue-50)',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={'0 0 16 16'}
      fill={color}
    >
      <path d="M3.99996 13.3327C3.63329 13.3327 3.3194 13.2021 3.05829 12.941C2.79718 12.6799 2.66663 12.366 2.66663 11.9993V7.33268C2.66663 6.96602 2.79718 6.65213 3.05829 6.39102C3.3194 6.1299 3.63329 5.99935 3.99996 5.99935C4.36663 5.99935 4.68051 6.1299 4.94163 6.39102C5.20274 6.65213 5.33329 6.96602 5.33329 7.33268V11.9993C5.33329 12.366 5.20274 12.6799 4.94163 12.941C4.68051 13.2021 4.36663 13.3327 3.99996 13.3327ZM7.99996 13.3327C7.63329 13.3327 7.3194 13.2021 7.05829 12.941C6.79718 12.6799 6.66663 12.366 6.66663 11.9993V3.99935C6.66663 3.63268 6.79718 3.31879 7.05829 3.05768C7.3194 2.79657 7.63329 2.66602 7.99996 2.66602C8.36663 2.66602 8.68051 2.79657 8.94163 3.05768C9.20274 3.31879 9.33329 3.63268 9.33329 3.99935V11.9993C9.33329 12.366 9.20274 12.6799 8.94163 12.941C8.68051 13.2021 8.36663 13.3327 7.99996 13.3327ZM12 13.3327C11.6333 13.3327 11.3194 13.2021 11.0583 12.941C10.7972 12.6799 10.6666 12.366 10.6666 11.9993V9.99935C10.6666 9.63268 10.7972 9.31879 11.0583 9.05768C11.3194 8.79657 11.6333 8.66602 12 8.66602C12.3666 8.66602 12.6805 8.79657 12.9416 9.05768C13.2027 9.31879 13.3333 9.63268 13.3333 9.99935V11.9993C13.3333 12.366 13.2027 12.6799 12.9416 12.941C12.6805 13.2021 12.3666 13.3327 12 13.3327Z" />
    </svg>
  );
};

export default BarChartIcon;
