import React, { useCallback } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as S from 'styles/common/list/SideBar/SideBarList.style';
import { UrlObject } from 'url';
import * as amplitude from '@amplitude/analytics-browser';

interface Props {
  list: {
    title: string;
    link: string | UrlObject;
    keyword: string[];
    icon?: JSX.Element;
    filledIcon?: JSX.Element;
  }[];
  headerItem?: JSX.Element;
}

const SideBarList = ({ list, headerItem = null }: Props) => {
  const router = useRouter();
  const scrollRef = useCallback((ref) => {
    if (ref) {
      ref.scrollIntoViewIfNeeded();
    }
  }, []);

  const isPicked = useCallback(
    (keywordList) => {
      let isPicked = false;
      for (let i = 0; i < keywordList?.length; i++) {
        const keyword = keywordList[i];
        if (router.asPath.startsWith(keyword)) {
          isPicked = true;
          break;
        }
      }
      return isPicked;
    },
    [router],
  );

  return (
    <S.SideBarListLayout>
      <div>
        {headerItem && headerItem}
        <ul>
          {list.map((data, key) => (
            <Link key={key} href={data.link}>
              <a>
                <S.SideBarListItem
                  className={`${isPicked(data.keyword) ? 'picked' : ''}`}
                  ref={scrollRef}
                  onClick={() => {
                    const eventProperties = {
                      buttonText: data.title,
                      redirectUrl: data.link,
                    };
                    amplitude.track(
                      `click_side_bar_${data.title}`,
                      eventProperties,
                    );
                  }}
                >
                  {data.icon ? (
                    <div>
                      {isPicked(data.keyword) && data.filledIcon
                        ? data.filledIcon
                        : data.icon}
                    </div>
                  ) : (
                    <></>
                  )}
                  <span>{data.title}</span>
                </S.SideBarListItem>
              </a>
            </Link>
          ))}
        </ul>
      </div>
    </S.SideBarListLayout>
  );
};

export default SideBarList;
