import React from 'react';

const SchoolIcon = ({ width = '16', height = '16', color = 'white' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4.0333 11.85C3.81108 11.7278 3.63886 11.5639 3.51663 11.3583C3.39441 11.1528 3.3333 10.9222 3.3333 10.6667V7.46665L1.7333 6.58332C1.61108 6.51665 1.52219 6.43332 1.46663 6.33332C1.41108 6.23332 1.3833 6.12221 1.3833 5.99999C1.3833 5.87776 1.41108 5.76665 1.46663 5.66665C1.52219 5.56665 1.61108 5.48332 1.7333 5.41665L7.36663 2.34999C7.46663 2.29443 7.56941 2.25276 7.67497 2.22499C7.78052 2.19721 7.88886 2.18332 7.99997 2.18332C8.11108 2.18332 8.21941 2.19721 8.32497 2.22499C8.43052 2.25276 8.5333 2.29443 8.6333 2.34999L14.9833 5.81665C15.0944 5.87221 15.1805 5.95276 15.2416 6.05832C15.3027 6.16387 15.3333 6.27776 15.3333 6.39999V10.6667C15.3333 10.8555 15.2694 11.0139 15.1416 11.1417C15.0139 11.2694 14.8555 11.3333 14.6666 11.3333C14.4777 11.3333 14.3194 11.2694 14.1916 11.1417C14.0639 11.0139 14 10.8555 14 10.6667V6.73332L12.6666 7.46665V10.6667C12.6666 10.9222 12.6055 11.1528 12.4833 11.3583C12.3611 11.5639 12.1889 11.7278 11.9666 11.85L8.6333 13.65C8.5333 13.7055 8.43052 13.7472 8.32497 13.775C8.21941 13.8028 8.11108 13.8167 7.99997 13.8167C7.88886 13.8167 7.78052 13.8028 7.67497 13.775C7.56941 13.7472 7.46663 13.7055 7.36663 13.65L4.0333 11.85ZM7.99997 8.46665L12.5666 5.99999L7.99997 3.53332L3.4333 5.99999L7.99997 8.46665ZM7.99997 12.4833L11.3333 10.6833V8.16665L8.64997 9.64999C8.54997 9.70554 8.44441 9.74721 8.3333 9.77499C8.22219 9.80276 8.11108 9.81665 7.99997 9.81665C7.88886 9.81665 7.77775 9.80276 7.66664 9.77499C7.55552 9.74721 7.44997 9.70554 7.34997 9.64999L4.66663 8.16665V10.6833L7.99997 12.4833Z"
        fill={color}
      />
    </svg>
  );
};
export const FilledSchoolIcon = ({
  width = '16',
  height = '16',
  color = 'white',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={'0 0 16 16'}
      fill="none"
    >
      <path
        d="M14.5004 10.6669V6.73359L9.13372 9.65026C8.93372 9.76137 8.72261 9.81693 8.50039 9.81693C8.27817 9.81693 8.06706 9.76137 7.86706 9.65026L2.23372 6.58359C2.1115 6.51693 2.02539 6.43359 1.97539 6.33359C1.92539 6.23359 1.90039 6.12248 1.90039 6.00026C1.90039 5.87804 1.92539 5.76693 1.97539 5.66693C2.02539 5.56693 2.1115 5.48359 2.23372 5.41693L7.86706 2.35026C7.96706 2.2947 8.06984 2.25304 8.17539 2.22526C8.28095 2.19748 8.38928 2.18359 8.50039 2.18359C8.6115 2.18359 8.71984 2.19748 8.82539 2.22526C8.93095 2.25304 9.03372 2.2947 9.13372 2.35026L15.4837 5.81693C15.5948 5.87248 15.6809 5.95304 15.7421 6.05859C15.8032 6.16415 15.8337 6.27804 15.8337 6.40026V10.6669C15.8337 10.8558 15.7698 11.0141 15.6421 11.1419C15.5143 11.2697 15.3559 11.3336 15.1671 11.3336C14.9782 11.3336 14.8198 11.2697 14.6921 11.1419C14.5643 11.0141 14.5004 10.8558 14.5004 10.6669ZM7.86706 13.6503L4.53372 11.8503C4.3115 11.728 4.13928 11.5614 4.01706 11.3503C3.89484 11.1391 3.83372 10.9114 3.83372 10.6669V8.13359L7.86706 10.3169C8.06706 10.428 8.27817 10.4836 8.50039 10.4836C8.72261 10.4836 8.93372 10.428 9.13372 10.3169L13.1671 8.13359V10.6669C13.1671 10.9114 13.1059 11.1391 12.9837 11.3503C12.8615 11.5614 12.6893 11.728 12.4671 11.8503L9.13372 13.6503C9.03372 13.7058 8.93095 13.7475 8.82539 13.7753C8.71984 13.803 8.6115 13.8169 8.50039 13.8169C8.38928 13.8169 8.28095 13.803 8.17539 13.7753C8.06984 13.7475 7.96706 13.7058 7.86706 13.6503Z"
        fill={color}
      />
    </svg>
  );
};

export default SchoolIcon;
