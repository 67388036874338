import { useUserInfoQuery } from '@apis/query/user';
import { UserType } from '@asset/enum/UserEnum';
import BottomNavigation from '@components/common/BottomNavigation';
import Lnb from '@components/common/nav/Lnb';
import MobileTopNavBar from '@components/common/nav/MobileTopNavBar';
import { useWindowSize } from '@public/hooks/useWindowSize';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';

const GlobalLayout = ({
  children,
  lnbItemList = [],
  snbElement = null,
  backgroundColor = 'var(--gray-100)',
  hideMobileTopNav = false,
  hideMobileBottomNav = false,
}: {
  children: React.ReactNode;
  lnbItemList?: { href: string; text: string; pageType: string }[];
  snbElement?: React.ReactNode | null;
  backgroundColor?: string;
  hideMobileTopNav?: boolean;
  hideMobileBottomNav?: boolean;
}) => {
  const router = useRouter();
  const { data: user } = useUserInfoQuery();
  const { isMobileView } = useWindowSize();
  const contentWrapperRef = useRef(null);
  const showLnb = !isMobileView && lnbItemList?.length > 0;
  const showSnb = !isMobileView && snbElement;
  const showMobileTopNav =
    !hideMobileTopNav &&
    isMobileView &&
    ((user?.type === UserType.STUDENT &&
      router.asPath.includes('my-study-records')) ||
      (user?.type === UserType.PARENT &&
        !router.asPath.includes('course/detail/')) ||
      user?.type === UserType.TEACHER);
  const showMobileBottomNav =
    user?.type === UserType.PARENT && !hideMobileBottomNav;
  const [isScrolled, setIsScroll] = useState(false);

  function checkScroll() {
    if (contentWrapperRef.current.scrollTop > 30) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  }

  useEffect(() => {
    const contentWrapper = contentWrapperRef?.current;
    function watchScroll() {
      contentWrapper?.addEventListener('scroll', checkScroll);
    }
    watchScroll();
    return () => {
      contentWrapper?.removeEventListener('scroll', checkScroll);
    };
  }, []);

  return (
    <>
      {showMobileTopNav ? (
        <MobileTopNavBar
          customStyle={{
            backgroundColor: router.asPath.includes('my-study-records')
              ? 'var(--gray-95)'
              : 'var(--gray-100)',
            borderBottom: isScrolled ? '1px solid var(--gray-80)' : 'none',
          }}
        />
      ) : (
        <></>
      )}
      <section
        className="flex w-full"
        style={{
          height: showMobileTopNav
            ? 'clac(100% - var(--height-mobile-nav))'
            : '100%',
        }}
      >
        {showSnb ? snbElement : <></>}
        <div
          className="flex flex-col items-center justify-center w-full"
          style={{
            backgroundColor: backgroundColor,
            height: isMobileView
              ? showMobileTopNav
                ? 'calc(100dvh - var(--height-mobile-nav))'
                : '100%'
              : 'calc(100dvh - var(--height-global-nav))',
            width: showSnb
              ? 'calc(100dvw - var(--width-close-side-bar))'
              : '100%',
          }}
        >
          {showLnb ? <Lnb list={lnbItemList} /> : <></>}
          <section
            className="flex justify-center w-full h-full overflow-y-scroll content-wrapper"
            ref={contentWrapperRef}
            style={{
              height: showLnb ? 'calc(100% - var(--height-local-nav))' : '100%',
            }}
          >
            {children}
          </section>
          {showMobileBottomNav ? isMobileView && <BottomNavigation /> : <></>}
        </div>
      </section>
    </>
  );
};

export default GlobalLayout;
