import React from 'react';

const HomeIcon = ({ width = '16', height = '16', color = 'white' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M3.99984 12.6666H5.99984V9.33331C5.99984 9.14442 6.06373 8.98609 6.1915 8.85831C6.31928 8.73054 6.47761 8.66665 6.6665 8.66665H9.33317C9.52206 8.66665 9.68039 8.73054 9.80817 8.85831C9.93595 8.98609 9.99984 9.14442 9.99984 9.33331V12.6666H11.9998V6.66665L7.99984 3.66665L3.99984 6.66665V12.6666ZM2.6665 12.6666V6.66665C2.6665 6.45554 2.71373 6.25554 2.80817 6.06665C2.90262 5.87776 3.03317 5.7222 3.19984 5.59998L7.19984 2.59998C7.43317 2.4222 7.69984 2.33331 7.99984 2.33331C8.29984 2.33331 8.5665 2.4222 8.79984 2.59998L12.7998 5.59998C12.9665 5.7222 13.0971 5.87776 13.1915 6.06665C13.2859 6.25554 13.3332 6.45554 13.3332 6.66665V12.6666C13.3332 13.0333 13.2026 13.3472 12.9415 13.6083C12.6804 13.8694 12.3665 14 11.9998 14H9.33317C9.14428 14 8.98595 13.9361 8.85817 13.8083C8.73039 13.6805 8.6665 13.5222 8.6665 13.3333V9.99998H7.33317V13.3333C7.33317 13.5222 7.26928 13.6805 7.1415 13.8083C7.01373 13.9361 6.85539 14 6.6665 14H3.99984C3.63317 14 3.31928 13.8694 3.05817 13.6083C2.79706 13.3472 2.6665 13.0333 2.6665 12.6666Z"
        fill={color}
      />
    </svg>
  );
};
export const FilledHomeIcon = ({
  width = '16',
  height = '16',
  color = 'white',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 16 16`}
      fill="none"
    >
      <path
        d="M3.16675 12.6663V6.66634C3.16675 6.45523 3.21397 6.25523 3.30841 6.06634C3.40286 5.87745 3.53341 5.7219 3.70008 5.59967L7.70008 2.59967C7.93341 2.4219 8.20008 2.33301 8.50008 2.33301C8.80008 2.33301 9.06675 2.4219 9.30008 2.59967L13.3001 5.59967C13.4667 5.7219 13.5973 5.87745 13.6917 6.06634C13.7862 6.25523 13.8334 6.45523 13.8334 6.66634V12.6663C13.8334 13.033 13.7029 13.3469 13.4417 13.608C13.1806 13.8691 12.8667 13.9997 12.5001 13.9997H10.5001C10.3112 13.9997 10.1529 13.9358 10.0251 13.808C9.8973 13.6802 9.83341 13.5219 9.83341 13.333V9.99967C9.83341 9.81079 9.76953 9.65245 9.64175 9.52467C9.51397 9.3969 9.35564 9.33301 9.16675 9.33301H7.83341C7.64453 9.33301 7.48619 9.3969 7.35841 9.52467C7.23064 9.65245 7.16675 9.81079 7.16675 9.99967V13.333C7.16675 13.5219 7.10286 13.6802 6.97508 13.808C6.8473 13.9358 6.68897 13.9997 6.50008 13.9997H4.50008C4.13341 13.9997 3.81953 13.8691 3.55841 13.608C3.2973 13.3469 3.16675 13.033 3.16675 12.6663Z"
        fill={color}
      />
    </svg>
  );
};

export default HomeIcon;
