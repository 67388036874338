export enum StudentPage {
  MY_ASSIGNMENTS = 'my-assignments',
  MY_TESTS = 'my-tests',
  MY_STUDY_MATERIALS = 'my-study-materials',
  MY_FEEDBACKS = 'my-feedbacks',
  MY_QUESTIONS = 'my-questions',
  MY_PLANS = 'my-plans',
  MY_SCHEDULE = 'my-schedule',
  MY_STUDY_RECORDS = 'my-study-records',
  MY_RANKING = 'my-ranking',
  MY_INFORMATION = 'my-information',
  MY_CREDITS = 'my-credits',
  LEVEL_TEST = 'level-test',
}

export enum TeacherPage {
  MAIN = 'main',
  CLASS = 'my-class',
  ASSIGNMENTS = 'my-assignments',
  TESTS = 'my-tests',
  STUDY_MATERIALS = 'my-study-materials',
  FEEDBACKS = 'my-feedbacks',
  QUESTIONS = 'my-questions',
  SCHEDULE = 'schedule',
  ROADMAP = 'roadmap',
  STUDY_RECORDS = 'my-study-records',
  RANKING = 'my-ranking',
  STUDY_CONTENT_REPORT = 'my-study-content',
  LEVEL_TEST = 'level-test',
  STUDENT_INFORMATION = 'student-information',
  COURSE_DETAIL = 'course/detail',
  CREDITS = 'credits',
}
