import React from 'react';
import * as S from '@styles/common/BottomNavigation.style';
import { FilledBarChartIcon } from '@asset/svg/BarChartIcon';
import { FilledCalendarTodayIcon } from '@asset/svg/CalendarTodayIcon';
import RoadMapIcon from '@asset/svg/RoadMapIcon';
import { useRouter } from 'next/router';
import { UrlObject } from 'url';
import {
  getMyRoadmapPageUrlObject,
  getMySchedulePageUrlObject,
  getMyStudyRecordsPageUrlObject,
} from 'routes/dashboard';
import { useSelectedStudentInfoQuery } from '@apis/query/user';

const BottomNavItem = ({
  icon,
  title,
  routingUrlObject,
  urlKeywords,
}: {
  icon: JSX.Element;
  title: string;
  routingUrlObject: UrlObject;
  urlKeywords: string[];
}) => {
  const router = useRouter();
  return (
    <S.BottomNavItem
      color={
        router.asPath.includes(routingUrlObject.pathname) ||
        urlKeywords.some((urlKeyword) => router.asPath.includes(urlKeyword))
          ? 'var(--gray-1O)'
          : 'var(--gray-60)'
      }
      onClick={() => {
        router.push(routingUrlObject);
      }}
    >
      {icon}
      <p>{title}</p>
    </S.BottomNavItem>
  );
};

const BottomNavigation = () => {
  const { data: selectedStudent } = useSelectedStudentInfoQuery();
  return (
    <S.BottomNavLayout>
      <BottomNavItem
        title={'리포트'}
        routingUrlObject={getMyStudyRecordsPageUrlObject({
          studentId: selectedStudent?.id,
        })}
        urlKeywords={[
          getMyStudyRecordsPageUrlObject({
            studentId: selectedStudent?.id,
          }).pathname,
        ]}
        icon={
          <FilledBarChartIcon
            width={'1.5rem'}
            height={'1.5rem'}
            color={'var(--gray-60)'}
          />
        }
      />
      <BottomNavItem
        title={'일정표'}
        routingUrlObject={getMySchedulePageUrlObject({
          studentId: selectedStudent?.id,
        })}
        urlKeywords={[
          getMySchedulePageUrlObject({
            studentId: selectedStudent?.id,
          }).pathname,
        ]}
        icon={
          <FilledCalendarTodayIcon
            width={'1.5rem'}
            height={'1.5rem'}
            color={'var(--gray-60)'}
          />
        }
      />
      <BottomNavItem
        title={'로드맵'}
        routingUrlObject={getMyRoadmapPageUrlObject({
          studentId: selectedStudent?.id,
        })}
        urlKeywords={['course/detail/']}
        icon={
          <RoadMapIcon
            width={'1.5rem'}
            height={'1.5rem'}
            color={'var(--gray-10)'}
          />
        }
      />
    </S.BottomNavLayout>
  );
};

export default BottomNavigation;
