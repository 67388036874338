import styled from 'styled-components';
import tw from 'twin.macro';

export const SearchInputStyle = styled.div`
  & {
    ${tw`flex rounded-lg bg-my-gray-100`}
    width: ${(props) => (props.width ? props.width : '100%')};

    input {
      ${tw`h-[2.5rem] py-2 pr-3 pl-9 rounded-lg border border-solid border-my-gray-70 text-body-md-500 text-my-gray-10`}
      flex-grow: 1;
      background: url('/common/icon/search.svg');
      background-position: 0.5rem center;
      background-size: 1.5rem;
      background-repeat: no-repeat;
      outline: none;
      position: relative;
    }

    input::placeholder {
      color: var(--gray-60);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;
    }

    input:hover::-webkit-search-decoration,
    input:hover::-webkit-search-cancel-button,
    input:hover::-webkit-search-results-button,
    input:hover::-webkit-search-results-decoration,
    input:focus::-webkit-search-decoration,
    input:focus::-webkit-search-cancel-button,
    input:focus::-webkit-search-results-button,
    input:focus::-webkit-search-results-decoration {
      -webkit-appearance: none;
      width: 1.25rem;
      height: 1.25rem;
      background: url('/common/icon/cancel-fill.svg') center center no-repeat;
      cursor: pointer;
      position: absolute;
      right: 10px;
    }

    input:focus {
      ${tw`rounded-lg border border-[2px] border-my-blue-50`}
      padding-top: calc(0.5rem - 1px);
      padding-bottom: calc(0.5rem - 1px);
      padding-right: calc(0.75rem - 1px);
      padding-left: calc(2.25rem - 1px);
    }
  }
`;
