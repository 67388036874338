import React from 'react';
import { Oval } from 'react-loader-spinner';

const LoadingSpinner = ({
  width = null,
  height = null,
  background = 'transparent',
}) => {
  return (
    <>
      <section className={`flex items-center justify-center`}>
        <Oval
          height={50}
          width={50}
          color="var(--blue-50)"
          secondaryColor="var(--gray-80)"
          visible={true}
          ariaLabel="oval-loading"
          strokeWidth={4}
          strokeWidthSecondary={4}
        />
      </section>

      <style jsx>{`
        section {
          width: ${width ? width : '100%'};
          min-height: ${height ? '' : 'calc(100vh - var(--height-global-nav))'};
          height: ${height ? height : '100%'};
          max-height: 100vh;
          background: ${background};
          opacity: 0.5;
        }
      `}</style>
    </>
  );
};

export default LoadingSpinner;
