import { LoginPageType } from 'constants/enum';
import { UrlObject } from 'url';

export const ACCOUNT_PAGE_URL_PREFIX = '/account/';

export const getPhoneAuthenticationResultPageUrlObject = (query: {
  redirectUrl: string;
}): UrlObject => {
  const urlString = `${ACCOUNT_PAGE_URL_PREFIX}phone-authentication/result/`;
  return { pathname: urlString, query: query };
};
export const getLoginPageUrlObject = (query: {
  loginPageType?: LoginPageType;
  redirectUrl?: string;
}): UrlObject => {
  const urlString = `${ACCOUNT_PAGE_URL_PREFIX}login/`;
  return { pathname: urlString, query: query };
};
