export enum UserType {
  STUDENT = 'student',
  TEACHER = 'teacher',
  PARENT = 'parent',
}

export enum TeacherPosition {
  MANAGER = 'MANAGER',
  MAIN = 'MAIN',
  TA = 'TA',
}

export enum TeacherStatus {
  ACTIVE = 'ACTIVE',
  LEAVE = 'LEAVE',
  QUIT = 'QUIT',
}
